import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import config from '@/../config.json'

/*
If the user is NOT authenticated =>
    - Redirect to: Homepage (/)
If the user is authenticated =>
    - Return true

NOTICE: This does not stop the user from not being acessible from the page.
        This basically stops someone who is not authenticated and has not broken the website
        to SEE it. All authorization must happen inside of firebase. Vue cannot stop unauthenticated
        users.
*/
export const redirectIfNotAuthencated = () => {
    const store = useStore()
    const router = useRouter()


    if(JSON.stringify(store.getters.getUser) === "{}" && !config.developmentMode){
        router.push("/")
        return
    } else {
        return true
    }
}