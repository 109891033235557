import { createStore } from 'vuex'

export default createStore({
  state: {
    user: [{}],
    alreadyVoted: [
      ["Example", "Pair"]
    ]
  },
  mutations: {
    setUser( state, payload ) {
      state.user[0] = payload.user
    },
    addVote( state, payload ) {
      state.alreadyVoted.push(payload)
    },
    clearVotes( state ) {
      state.alreadyVoted = []
    }

  },
  actions: {
  },
  modules: {
  },
  getters: {
    getUser ( state ){
      return state.user[0]
    },
    getAlreadyVoted ( state ){
      return state.alreadyVoted
    }
  }
})
