import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Polling from '@/views/Polling.vue'
import Thanks from '@/views/Thanks.vue'
import Stats from '@/views/seeCurrentStats.vue'
import Changelog from '@/views/Changelog.vue'
import FAQ from '@/views/FAQ.vue'
import Suguestions from '@/views/VoteForNextWeek.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/polling',
    name: "Polling",
    component: Polling
  },
  {
    path: '/thanks',
    name: "Thanks",
    component: Thanks
  },
  {
    path: '/stats',
    name: "Stats",
    component: Stats
  },
  {
    path: '/changelog',
    name: "Changelog",
    component: Changelog
  },
  {
    path: '/faq',
    name: "FAQ",
    component: FAQ
  },
  {
    path: '/suguestions',
    name: "Suguestions",
    component: Suguestions
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
