<template>
    <div>
        <Header />
        <router-link to="/">
            <button class="button  is-link is-light mt-5 mb-5"> Go back</button>
        </router-link>
        <br>
        <span class="title mt-5"> FAQ </span><br>
        <br>
        <div class="mt-5 mb-5"></div>
        
        <span class="header mb-3">Q: Is this endless</span><br>
        <div class="text-container">
            <span class="text">
                A: Well no, not exactly. The site compares all around 90 food combinations, amounting in around 3800 combinations (1081 with Vegan Mode).
                So, long story short there is a end but it is <u>very hard to reach it.</u> If you do, I will personally congratulate you.
            </span>
        </div>
        <span class="header mt-5 mb-3">Q: Did you make this</span><br>
        <div class="text-container">
            <span class="text">
                A: Yes. I spent a week making this. The site uses <b>Vue (VueJS/Vue3)</b> for front-end and the web application you
                are using right now to read this. The backend is <b>NodeJS (ExpressJS)</b> and the database is <b>MongoDB</b> (via mongoose).

                If you actually want to learn more, I can walk you through the code. I would be more than happy to talk about that. Like please, lets talk about it.
            </span>
        </div>
        <span class="header mt-5 mb-3">Q: Please add more foods</span><br>
        <div class="text-container">
            <span class="text">
                A: Sure. Just email me about it and i'll consider adding it assuming it is a real dish. Not a ingredient.
            </span>
        </div>
        <span class="header mt-5 mb-3">Q: I have more questions</span><br>
        <div class="text-container">
            <span class="text">
                A: First off, thats less of a question and more of a statement. And second, feel free to contact me about any questions.
                You can email me using my school email or at buisness@alexdr.tech.
            </span>
        </div>
    </div>
</template>

<style lang="scss">
    .title{
        font-size: 37.5pt;
        font-family: inter;
        font-weight: bold;
    }

    .subheader{
        font-size: 12pt;
        font-family: inter;
        color:gray;
    }

    .header{
        font-size: 22.5pt;
        font-family: inter;
        font-weight: bold;
        margin-top: 15pt;
    }

    .text{
        font-size: 13t;
    }

    .text-container{
        max-width: 700pt;
        margin: auto;
        margin-top: 10pt;
        margin-bottom: 15pt;
    }

    .green-text{
        color: hsl(141, 53%, 53%);
    }
</style>

<script>
import Header from '@/components/Header.vue'

export default {
    components: {
        Header
    }
}
</script>

