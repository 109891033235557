<template>
    <div>
        <Header />
        <router-link to="/">
            <button class="button  is-link is-light mt-5 mb-5"> Go back</button>
        </router-link>
        <br>
        <span class="title mt-5"> Changelog Version 1.5 (inc. 1.3/1.4)</span><br>
        <span class="subheader mt-1">Release Date: 1/9/2022 (1.3/1.4: 1/7/2022 and 1/8/2022). By: Alexander Bobo</span>
        <br>
        <div class="mt-5 mb-5"></div>
        <span class="header mb-3"><span class="green-text">Added:</span> Vegan mode (1.5)</span><br>
        <div class="text-container">
            <span class="text">
                If you are Vegan and don't want to promote not-vegan dishes. You can now enable Vegan mode. By 
                selecting Vegan mode, we will now only show you vegan options. The one downside, is that if you want
                a lot of votes, vegan mode limits you to 1081 because of the amount of combinations.
            </span>
        </div>
        <span class="header mb-3"><span class="green-text">Patched:</span> Kickouts due to brute forced pairs. (1.3)</span><br>
        <div class="text-container">
            <span class="text">
                The site randomly generates pairs. And before to determine if you had done all the pairs, it brute forced to see if there was another pair.
                This was for the most part fine, it was not the best for efficency but it still worked. For users with not many votes this wasn't much of an issue.
                But for users with a lot of votes, it became a problem. They couldn't get very far without being kicked. The solution now is that the site calculates
                the number of combinations and than compares that. If the number of combinations is higher or than the number of votes, it kicks (Goes to thank you screen)
                the user. 
            </span>
        </div>
        <span class="header mt-3 mb-3"><span class="green-text">Added:</span> Choose vote for next week (1.4)</span><br>
        <div class="text-container">
            <span class="text">
                You can now walk on over to our suguestions page, and choose for a new poll next week if you think this should continue. You can reach the page on
                the stats and polling page.
            </span>
        </div>
    </div>
</template>

<style lang="scss">
    .title{
        font-size: 37.5pt;
        font-family: inter;
        font-weight: bold;
    }

    .subheader{
        font-size: 12pt;
        font-family: inter;
        color:gray;
    }

    .header{
        font-size: 22.5pt;
        font-family: inter;
        font-weight: bold;
    }

    .text{
        font-size: 13t;
    }

    .text-container{
        max-width: 700pt;
        margin: auto;
        margin-top: 10pt;
    }

    .green-text{
        color: hsl(141, 53%, 53%);
    }
</style>

<script>
import Header from '@/components/Header.vue'

export default {
    components: {
        Header
    }
}
</script>

