export default [
    "Hamburger",
    "Pizza",
    "Burrito",
    "Taco",
    "PB&J",
    "Mozzerella Sticks",
  "Shrimps",
  "Salad",
  "Cookies",
  "Ice Cream",
  "Chocolate Cake",
  "Grilled Chicken",
  "Hot Dog",
  "Cheese Dog",
  "Jell-O",
  "Sandwich (normal)",
  "Ranch Dressing",
  "Pumpkin Pie",
  "Apple Pie",
  "Spaghetti and Meatballs",
  "Avocado Toast",
  "New-York-Style Cheesecake",
  "Deep Dish Pizza",
  "Macaroni and Cheese",
  "Fried Chicken",
  "Cornbread",
  "Steak",
  "Fish and Chips",
  "Club Sandwich",
  "Cheesesteak",
  "Banana Split",
  "Banana Bread",
  "Ice Cream Sandwich",
  "Cihicken and Waffles",
    "Cupcakes",
    "Cake",
    "Eggs Benedict",
    "Key Lime Pie",
    "Cup of Coffee",
    "Graham Cracker",
    "Peperoni Pizza",
    "Buffalo Wings",
    "Chicken Nuggets",
    "Barbecue Ribs",
    "Curry",
    "Salmon",
    "Couscous",
    "Pecan Pie",
    "Pot Pie",
    "Onion Rings",
    "Snickerdoodle",
    "Corn on the Cob",
    "Corn Flakes",
    "Pancakes",
    "Crepes",
    "Meatloaf",
    "Candy Corn",
    "Turkey",
    "Jelly Beans",
    "Coleslaw",
    "Crab Cake",
    "S'more",
    "BLT Sandwich",
    "Frozen Yougurt",
    "Grilled Cheese",
    "Milkshake",
    "Doughnut",
    "Brownies",
    "Cheeseburger",
    "Apples",
    "Ice Cream",
    "Ledo's Pizza",
    "Biscuits and Gravy",
    "Baguette ",
    "Churros",
    "Macrons",
    "Pumpkin bread",
    "Waffles",
    "Hot cocoa",
    "Water",
    "Lemonade",
    "Lettuce smoothie",
    "Vanilla cake",
    "Chips",
    "Goldfish",
    "Pretzel",
    "Pasta",
    "Fries"
]

export const veganFoods = [
  "Burrito",
  "PB&J",
"Shrimps",
"Salad",
"Cookies",
"Ice Cream",
"Jell-O",
"Ranch Dressing",
"Pumpkin Pie",
"Apple Pie",
"Avocado Toast",
"Cornbread",
"Banana Split",
"Banana Bread",
"Ice Cream Sandwich",
  "Cupcakes",
  "Cake",
  "Eggs Benedict",
  "Key Lime Pie",
  "Cup of Coffee",
  "Graham Cracker",
  "Couscous",
  "Pecan Pie",
  "Pot Pie",
  "Onion Rings",
  "Corn on the Cob",
  "Corn Flakes",
  "Crepes",
  "Jelly Beans",
  "Coleslaw",
  "Frozen Yougurt",
  "Brownies",
  "Apples",
  "Baguette ",
  "Churros",
  "Macrons",
  "Pumpkin bread",
  "Waffles",
  "Hot cocoa",
  "Water",
  "Lemonade",
  "Lettuce smoothie",
  "Vanilla cake",
  "Chips",
  "Goldfish",
  "Pretzel",
  "Pasta",
  "Fries"
]
