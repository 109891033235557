<template>
    <div>
        <Header />

        
        <h1 class="title is-1" style="margin-top: 50pt;">Thanks for participating!</h1><br>
        <h2 class="title is-4">You have submited more than enough data! Thanks!</h2>
        <button v-if="config.developmentMode == true" class="button is-medium is-warning" @click="clearVueX">[Dev] Reset VueX Data</button><br>
        <router-link to="/polling">
            <button 
                v-if="config.developmentMode == true && showRedirect()" 
                class="button is-medium is-link is-light mt-3"
            >[Dev] Return to polling</button>
            <button 
                v-else-if="config.developmentMode" 
                class="button is-medium is-link is-light mt-3" 
                disabled
            >[Dev] Return to polling</button>
        </router-link>

        <iframe style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-whichfoodisbetterprod-tijzy/embed/charts?id=3636d4e0-1dde-4f48-8377-2ef717f92388&maxDataAge=60&theme=light&autoRefresh=true"></iframe>
        <iframe style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="640" height="480" src="https://charts.mongodb.com/charts-whichfoodisbetterprod-tijzy/embed/charts?id=3c87364e-133c-4d45-bb86-5ba8c3a7b78e&maxDataAge=60&theme=light&autoRefresh=true"></iframe>
    </div>
</template>



<script>
    import Header from '@/components/Header.vue'
    import { useStore } from 'vuex'
    import config from '@/../config.json'
    import Firebase from '@/Firebs.js'
    export default{
        name: "Thanks",
        components: {
            Header
        },
        setup(){
            const store = useStore()
            let hasBeenReset = false
            const clearVueX = () => {

                store.commit('clearVotes', "")
                Firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                        "token": idToken
                        });

                        var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                        };

                        fetch(config.backendURL+"/getVotes", requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            let currentData = JSON.parse(result)
                            console.log(currentData.votes)
                            console.log(currentData.votes[0].vote1)
                            store.commit('clearVotes', "")
                            for(let i = 0; i < currentData.votes.length; i++){
                                hasBeenReset = true
                                store.commit('addVote', [currentData.votes[i].vote1, currentData.votes[i].vote2])
                            }
                        })
                        .catch(error => console.log('error', error));
                    }).catch(function(error) {
                        console.log(error)
                    });
            }
        

            const showRedirect = () => {
                return (store.getters.getAlreadyVoted.length === 0 || hasBeenReset == true) ? true : false;
            }

            return { clearVueX, config, showRedirect }
        }
    }
</script>