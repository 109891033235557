<template>
    <div>
        <Header />

        <div class="mt-5 mb-5"></div>

        <span class="title mt-5">Vote for next weeks poll</span>
        <br>
        <button class="button is-large is-danger is-outlined mt-3 mb-3 is-light" @click="submit(`What's the best movie?`)" >What's the best movie?</button><br>
        <button class="button is-large is-primary is-outlined mt-3 mb-3 is-light" @click="submit(`What's the best restaurant?`)">What's the best restaurant?</button><br>
        <button class="button is-large is-info is-outlined mt-3 mb-3 is-light" @click="submit(`Who's the best actor/actress?`)">Who's the best actor/actress?</button><br>
        <button class="button is-large is-warning is-outlined mt-3 mb-3 is-light" @click="submit(`What's the best TV show?`)">What's the best TV show?</button><br>

        <router-link to="/polling">
            <button class="button is-link is-light mt-4">Go to polling</button>
        </router-link>
        <br>
        
    </div>
</template>

<script>
    import Header from '../components/Header.vue'
    import firebase from '@/Firebs.js'
    import config from '@/../config.json'
    import {redirectIfNotAuthencated} from '@/lib/routeGuard.js'
    export default {
        components: {
            Header
        },
        setup(){
            redirectIfNotAuthencated()
            const submit = (sug) => {
                firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                    "token": idToken,
                    "suggestion": sug
                    });

                    var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                    };

                    fetch(config.backendURL +"/submitSuguestion", requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                })
            }

            return {
                submit
            }
        }
    }
</script>





<style lang="scss">
    .title{
        font-size: 37.5pt;
        font-family: inter;
        font-weight: bold;
    }

    .subheader{
        font-size: 12pt;
        font-family: inter;
        color:gray;
    }

    .header{
        font-size: 22.5pt;
        font-family: inter;
        font-weight: bold;
        margin-top: 15pt;
    }

    .text{
        font-size: 13t;
    }

    .text-container{
        max-width: 700pt;
        margin: auto;
        margin-top: 10pt;
        margin-bottom: 15pt;
    }

    .green-text{
        color: hsl(141, 53%, 53%);
    }
</style>
