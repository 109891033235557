import firebase from 'firebase';

const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyD4LeueHDSa4_ylDH0ysMlIuoWAEfZaeqs",
    authDomain: "whichfoodisbetter.firebaseapp.com",
    projectId: "whichfoodisbetter",
    storageBucket: "whichfoodisbetter.appspot.com",
    messagingSenderId: "412191632740",
    appId: "1:412191632740:web:648154eed0191b9f275e71"
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;