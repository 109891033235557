<template>
    <div>
        <Header class="site-header"/>

        <div v-if="store.getters.getUser.email == 'snickel22@student.w-e-s.org' || store.getters.getUser.name == 'Deoz Carter'">
            <span class="header">Among us sus?</span><br>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <span class="header">What's better?</span><br>
        <router-link to="/stats">
            <button 
                class="button is-medium is-link is-light mt-2 mb-3"
            >See what's winning</button>
        </router-link><br>
        <input type="checkbox" v-model="data.veganMode" />
        <span class="setting" > Vegan mode?</span>
        <div class="options-box">
            <button class="option" @click="whenButtonIsClicked.option1(data.options[0], generatePair)">
                <span>{{ data.options[0] }}</span>
            </button>
            <button class="option last" @click="whenButtonIsClicked.option2(data.options[1], generatePair)">
                <span>{{ data.options[1] }}</span>
            </button>
        </div>
        <router-link to="/suguestions">
            <button class="button is-link mb-3 is-light mt-4">Vote to pick a poll for next week</button>
        </router-link>
        <div class="mt-3 mb-3"></div>

    </div>
</template>

<style lang="scss" scoped>
    @import '@/styles/Polling/polling.scss';
</style>


<script>
    import Header from '@/components/Header.vue'
    import { useStore } from 'vuex'
    import listOfOptions, {veganFoods} from '@/lib/options.js'
    import { reactive, watchEffect } from 'vue'
    import { useRouter } from 'vue-router'
    import firebase from '@/Firebs.js'
    import config from '@/../config.json'
    import { redirectIfNotAuthencated } from '@/lib/routeGuard.js'
    import { combinations } from '@/lib/polling.js'

    export default {
        name: "Polling",
        components: {
            Header
        },
        setup(){
            const store = useStore()
            const router = useRouter()
            redirectIfNotAuthencated()

            const data = reactive({
                options: [],
                veganMode: false
            })

            

            let numsOfCombo = (data.veganMode) ?  combinations(veganFoods.length, 2) : combinations(listOfOptions.length, 2);
            
            // combinations(listOfOptions.length, 2)

            console.log(numsOfCombo)
            
            const generatePair = () => {
                const optionsList = (data.veganMode) ?  veganFoods : listOfOptions ;
                

                // generate 2 random numbers
                let randomNumber1 = Math.floor(Math.random() * optionsList.length)
                let randomNumber2 = Math.floor(Math.random() * optionsList.length)


                let options = [
                    optionsList[randomNumber1],
                    optionsList[randomNumber2]
                ]

                // Checking rules

                    //Checking that these have not already been asked
                    for(let i = 0; i != store.getters.getAlreadyVoted.length; i++){
                        if(
                            store.getters.getAlreadyVoted[i][0] == options[0] && store.getters.getAlreadyVoted[i][1] == options[1] ||
                            store.getters.getAlreadyVoted[i][0] == options[1] && store.getters.getAlreadyVoted[i][1] == options[0]
                        ){
                            console.log("already exists")
                            // combinations
                            if(numsOfCombo == store.getters.getAlreadyVoted.length){
                                router.push("/thanks")
                                return
                            }
                            else{
                                generatePair()
                            }

                            generatePair()
                            return
                        }
                    }
                    // Do not allow the same options on both sides
                    if(randomNumber1 === randomNumber2){
                        console.log(" A clashed to be fixed")
                        generatePair()
                        return
                    }
                data.options = options

                // Adding to already polled
                store.commit('addVote', options)

            }
            generatePair()
            generatePair()

            watchEffect(() => {
                console.log("Settings change: Vegan mode is", data.veganMode)
                numsOfCombo = (data.veganMode) ?  combinations(veganFoods.length, 2) : combinations(listOfOptions.length, 2);
                generatePair()
            })

            const submitToServer = (pair, chosen) => {
                console.log("Submitting to server ", pair)
                firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                    "token": idToken,
                    "vote": pair,
                    "option": chosen
                    });

                    var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                    };

                    fetch(config.backendURL + "/createVote", requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                });
            }

            const whenButtonIsClicked = {
                option1: (item, randomize) => {
                    console.log("Option 1 is clicked, ITEM: ", item)
                    submitToServer(data.options, item)
                    generatePair()
                },
                option2: (item, randomize) => {
                    console.log("Option 2 is clicked, ITEM: ", item)
                    submitToServer(data.options, item)
                    generatePair()
                }
            }

            

            return {
                data,
                whenButtonIsClicked,
                store
            }

        }
    }
</script>
