<template>
    <div class="homepage-root-div">
        <Header />

        <div class="lead-header">Answering the question of</div>
        <div class="header">What's the best food</div>
        <div class="sub-header">in middle-school</div>
        <button class="button is-large is-dark mt-5 button-font" @click="login">Sign in and let's find out</button><br>
        <span class="warning">You can only sign in with a approved email domain.</span><br>
        <router-link to="/changelog">
            <button class="mt-2 button  is-light is-success">
                <span class="tag is-warning is-light mr-2">V.1.5</span>
                Changelog / Updates
            </button>
        </router-link>
        <br>
        <router-link to="/faq">
            <button class="button mt-2 is-light is-small is-link">Frequently Asked Questions</button>
        </router-link>
        <button v-if="config.developmentMode == true && config.loginPermsWithoutDevMode == true" class="button is-warning mt-5" @click="goToPolling">[Dev] Continue to Polling without login</button>
        <button v-else-if="config.developmentMode == true && config.loginPermsWithoutDevMode == false" class="button is-warning mt-5" @click="goToPolling" disabled>[Dev] Continue to Polling without login</button>

        <div class="footer-rules">
            By signing in with Google we do not collect any personal information but your email.<br>
            <b>This site was developed and is maintained and owned by Alexander Bobo.</b><br>
            <a>Contact me: buisness@alexdr.tech for inquiries.</a>
            <div class="mt-1 mb-2"></div>
        </div>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import firebase from '@/Firebs.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import config from '@/../config.json'

export default {
    name: "Home",
    components: {
        Header
    },
    setup(){
        const store = useStore()
        const router = useRouter()
        
        const login = () => {

            var provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth()
                .signInWithPopup(provider)
                .then((result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    var credential = result.credential;

                    // This gives you a Google Access Token. You can use it to access the Google API.
                    var token = credential.accessToken;
                    // The signed-in user info.
                    var user = result.user;
                    
                    // if user.email includes a approved domain
                    if(!user.email.includes(config.domainRules.domain)  && config.domainRules.lockToDomain == true){
                        // redirect to the next page
                        if("layladanielian@gmail.com" == user.email){
                            alert("your layla so continue. and go on child.")
                        } else if("uOr1jsFLBBc1mD3F23pwNTVQBr73" == user.uid){
                            alert("No. please stop. no hables stupid.")
                        }  else{
                            alert("You can only sign in with a approved email domain.")
                            return
                        }
                    }


                    // is this a new user?
                    console.log(result.additionalUserInfo.isNewUser)

                    console.log(result)
                    

                    // Creating a token and getting current votes from server
                    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                        "token": idToken
                        });

                        var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                        };

                        fetch(config.backendURL+"/getVotes", requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            let currentData = JSON.parse(result)
                            console.log(currentData.votes)
                            console.log(currentData.votes[0].vote1)
                            store.commit('clearVotes', "")
                            for(let i = 0; i < currentData.votes.length; i++){
                                
                                store.commit('addVote', [currentData.votes[i].vote1, currentData.votes[i].vote2])
                            }
                        })
                        .catch(error => console.log('error', error));
                    }).catch(function(error) {
                        console.log(error)
                    });

                    // Adding user to vuex
                    console.log("Commiting user data to VueX")
                    store.commit('setUser', result)
                    router.push('/polling')

                }).catch((error) => {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
                    // ...
                });
        }

        return { login, config, goToPolling: () => { router.push('/polling') } }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/styles/Home/home.scss';
</style>

